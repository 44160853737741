import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'       
import 'primeicons/primeicons.css'
import "primeflex/primeflex.css";

import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';

import VueClipboard from 'vue3-clipboard'

createApp(App)
    .use(PrimeVue)
    .use(store)
    .use(router)
    .use(ToastService)
    .use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true,
    })
    .component('Toast', Toast)
    .mount('#app')
