import { createStore } from 'vuex'

export default createStore({
  state: {
    administration: {
      integrators: {
        list: [],
        selected: []
      },
      users: {
        list: [],
        selected: []
      },
      tokens: {
        list: [],
        selected: []
      }
    },
    http: {
      headers: {
        "Token-Authorization": ""
      }
    }
  },
  getters: {
    selectedUser(state){
      return state.administration.users.selected
    },
    selectedIntegratorId(state) {
      return state.administration.integrators.selected.id
    },
    selectedUserId(state) {
      return state.administration.users.selected.id
    },
    getIntegrators(state){
      return state.administration.integrators.list
    },
    getTokens(state){
      return state.administration.tokens.list
    },
    getUsers(state){
      return state.administration.users.list
    },
    getIntegrator: (state) => (id) =>{
      return state.administration.integrators.list.find(integrator => integrator.id === id)
    },
    getUser: (state) => (id) =>{
      return state.administration.users.list.find(user => user.id === id)
    }
  },
  mutations: {
    setSelectedUsers(state, selectedUsers){
      state.administration.users.selected = selectedUsers
    },
    setSelectedIntegrator(state, selectedIntegrator){
      state.administration.integrators.selected = selectedIntegrator
    },
    setIntegrators(state, integratorsList){
      state.administration.integrators.list = integratorsList
    },
    setUsers(state, usersList){
      state.administration.users.list = usersList
    },
    setTokens(state, tokensList){
      state.administration.tokens.list = tokensList
    },
    addIntegrator(state, integrator){
      state.administration.integrators.list.unshift(integrator)
    },
    addUser(state, user){
      state.administration.users.list.unshift(user)
    },
    addToken(state, token){
      state.administration.tokens.list.unshift(token)
    },
    updateIntegrator(state, integrator){
      state.administration.integrators.list.map(int => {
        if(int.id === integrator.id){
          return integrator
        }
      })
    },
    updateUser(state, user){
      state.administration.users.list.map(int => {
        if(int.id === user.id){
          return user
        }
      })
    },
    deactivateUsers(state, ids){
      state.administration.users.list = state.administration.users.list.filter(user => !ids.includes(user.id))
    },
    deactivateIntegrator(state, integrator_id){
      state.administration.integrators.list = state.administration.integrators.list.filter(integrator => integrator.id !== integrator_id )
    },
    deleteToken(state, token_id){
      state.administration.tokens.list = state.administration.tokens.list.filter(token => token.id !== token_id )
    },
    setAdminToken(state, token){
      state.http.headers['Token-Authorization'] = token
    }
  },
  actions: {
  },
  modules: {
  }
})
