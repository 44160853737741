import { createRouter, createWebHashHistory } from 'vue-router'
import store from "../store/index"

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/administration/LoginHome')
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/administration/AdminHome'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/administration/AdminHome'),
    meta: {
      requiresLogin: true
    }
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresLogin)){
    if(store.state.http.headers['Token-Authorization'] === ""){
      next({name: "login"})
      return
    }
  }
  next()
})

export default router
